import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore'; // Importando FieldValue

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);


var firebaseConfig2 = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY2,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN2,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL2,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID2,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET2,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID2,
  appId: process.env.REACT_APP_FIREBASE_APP_ID2,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID2
};

// Inicializar o segundo app com um nome específico
const app2 = initializeApp(firebaseConfig2, "secondary");
export const db2 = getFirestore(app2);