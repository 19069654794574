// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from './firebase';
import { onSnapshot,  doc, query, collection, where } from "firebase/firestore";
import { startOfDay, endOfDay } from 'date-fns';

const AuthContext = createContext();


export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pedidos, setPedidos] = useState('orders');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);


  const [companiesCollection, setCompaniesCollection] = useState();

  useEffect(() => {
    if (currentUser) {
      const unsubscribe = startcompaniesListener(setCompaniesCollection);
      return () => unsubscribe();
    }
  }, [currentUser]);

  const startcompaniesListener = (setCompaniesCollection) => {
    const companiesRef = doc(db, "companies", currentUser.uid);

    const companiesListener = onSnapshot(companiesRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        // Se o documento existir, atualize setCompaniesCollection com os dados do documento
        setCompaniesCollection({
          id: docSnapshot.id,
          ...docSnapshot.data(),
        });
      } else {
        // Se o documento não existir, defina setCompaniesCollection como null ou um valor padrão, conforme necessário
        setCompaniesCollection(null);
      }
    });

    return companiesListener;
  };

  const [statusGeral, setStatusGeral] = useState(null);

useEffect(() => {
  if (currentUser) {
    const unsubscribe = startStatusGeralListener(setStatusGeral);
    return () => unsubscribe();
  }
}, [currentUser]);

const startStatusGeralListener = (setStatusGeral ) => {

   //const documentoRef = doc(db, 'statusGeral2', 'HoX5CZjstwi1RnArV6bZ');
   const documentoRef = doc(db, 'statusGeral', '7aR3cmTSMUYkM9mcIRaD');

  const statusGeralListener = onSnapshot(documentoRef, (doc) => {
    if (doc.exists()) {
      setStatusGeral(doc.data());
    } else {
      console.log("Documento não encontrado");
    }
  }, (error) => {
    console.error("Erro em onSnapshot:", error);
  });

  return statusGeralListener;
};
  

  const [configurationsCollection, setconfigurationsCollection] = useState();

  useEffect(() => {
    if (currentUser) {
      const unsubscribe = startconfigurationsListener(setconfigurationsCollection);
      return () => unsubscribe();
    }
  }, [currentUser]);

  const startconfigurationsListener = (setconfigurationsCollection) => {
    const configurationsRef = doc(db, "configurations", currentUser.uid);

    const configurationsListener = onSnapshot(configurationsRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        // Se o documento existir, atualize setconfigurationsCollection com os dados do documento
        setconfigurationsCollection({
          id: docSnapshot.id,
          ...docSnapshot.data(),
        });
      } else {
        // Se o documento não existir, defina setconfigurationsCollection como null ou um valor padrão, conforme necessário
        setconfigurationsCollection(null);
      }
    });

    return configurationsListener;
  };

  const [ordersCollection, setOrdersCollection] = useState([]);

  useEffect(() => {
    if (currentUser) {
      const unsubscribe = startOrdersListener(setOrdersCollection);
      return () => unsubscribe();
    } 
  }, [currentUser]);
  
  const startOrdersListener = (setOrdersCollection) => {
    const todayStart = startOfDay(new Date());
    const todayEnd = endOfDay(new Date());
  
    const ordersListener = onSnapshot(
      query(
 
        collection(db, pedidos),
        where("company.uid", "==", currentUser.uid),
        where("order.orderDate", ">=", todayStart),
        where("order.orderDate", "<=", todayEnd)
      ),
      (snapshot) => {
        const ordersData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setOrdersCollection(ordersData);
      });
    return ordersListener;
  };

  const value = {
    currentUser,
    ordersCollection,
    companiesCollection,
    configurationsCollection,
    statusGeral,
    pedidos
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
